import React from 'react';
import CustomerCard from './CustomerCard';
import styled from 'styled-components';

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionTitle = styled.h2`
  color: #007bff;
  margin: 0;
  padding: 0;
  font-size: 24px;
`;

const Section = styled.div`
  padding: 0;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const CardWrapper = styled.div`
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const groupCustomersByFirstLetter = (customers) => {
  const result = customers.reduce((groups, customer) => {
    const firstLetter = customer.name.charAt(0).toUpperCase();
    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }
    groups[firstLetter].push(customer);
    return groups;
  }, {});
  Object.keys(result).forEach(key=>{
    result[key] = result[key].sort((e1,e2)=>{
      if (e1.name < e2.name) {
        return -1; // Swap
      }
      if (e1.name > e2.name) {
        return 1; // Don't swap
      }
      return 0; // Equal
    })
  })
  return result;
};

function CustomerList({ customers, onSelectCustomer, onDeleteHandler }) {
  const groupedCustomers = groupCustomersByFirstLetter(customers);
  return (
    <List>
      {Object.keys(groupedCustomers).sort().map(letter => (
        <Section key={letter}>
          <SectionTitle>{letter}</SectionTitle>
          <CardContainer>
            {groupedCustomers[letter].map(customer => (
              <CardWrapper onClick = {(e)=>onSelectCustomer(customer)} key={customer.id}>
                <CustomerCard customer={customer} onDelete={onDeleteHandler}/>
              </CardWrapper>
            ))}
          </CardContainer>
        </Section>
      ))}
    </List>
  );
}

export default CustomerList;
