import React, { useState, useEffect } from 'react';
import ActivityTable from '../../components/Activity/ActivityTable';

const ActivityPage = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    // Simulación de datos
    const simulatedData = [
      {
        id: '1',
        date: 'Sunday',
        time: 'Jun 2, 2024 at 11:01 PM',
        customerName: 'Cashell ES',
        customerId: '10048',
        serviceLocation: '17101 Cashell Road, Rockville, MD',
        container: 'MD 6-06 | 3.00yd³ | Recycle',
        activity: 'Recycle',
        fullness: '40%',
        image: 'https://via.placeholder.com/100x80', // Reemplaza con la URL de la imagen real
      },
      {
        id: '2',
        date: 'Sunday',
        time: 'Jun 2, 2024 at 11:00 PM',
        customerName: 'Bryant Woods ES',
        customerId: '10004',
        serviceLocation: '5450 Blue Heron Lane, Columbia, MD',
        container: 'MD 1-04 | 3.00yd³ | Recycle',
        activity: 'Recycle',
        fullness: '20%',
        image: 'https://via.placeholder.com/100x80', // Reemplaza con la URL de la imagen real
      },
    ];

    setActivities(simulatedData);
  }, []);

  return (
    <div>
      <h1>Activity</h1>
      <div>
        <select>
          <option>Date - Most to least recent</option>
          <option>Date - Least to most recent</option>
        </select>
      </div>
      <ActivityTable activities={activities} />
    </div>
  );
};

export default ActivityPage;
