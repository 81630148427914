import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import outputs from "./aws-exports";
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from './styles/globalStyles';
import AppRouter from './AppRouter';
import Sidebar from './components/Sidebar';
import styled from 'styled-components';
Amplify.configure(outputs);

const AppContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  margin-left: 250px;
  padding: 20px;
  flex-grow: 1;
  transition: margin-left 0.3s;
  ${props => !props.isSidebarVisible && 'margin-left: 0;'}
`;
const MainContentWrapper = ({ signOut, user }) => {
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(true);
  return (
    <>
      {user && (
        <>
          <Sidebar setIsSidebarVisible={setIsSidebarVisible} signOut={signOut} user={user} />
          <MainContent isSidebarVisible={isSidebarVisible}>
            <AppRouter />
          </MainContent>
        </>
      )}
      {!user && <>Logged out</>}
    </>
  );
};
export default function App() {
  return (
    <Authenticator>
      {({ signOut, user }) => {
        return <Router>
          <GlobalStyle />
          <AppContainer>
            <MainContentWrapper signOut={signOut} user={user} />
          </AppContainer>
        </Router>
      }}
    </Authenticator>
  );
}


/*!SECTION(
        <main>
          <h1>Hello {user?.username}</h1>
          <button onClick={signOut}>Sign out</button>
        </main>
      )}*/