import React, { useState, useEffect } from 'react';
import ContainerTable from '../../components/WasteMetering/ContainerTable';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const ContainersPage = () => {
  const [containers, setContainers] = useState([]);

  useEffect(() => {
    // Simulación de datos
    const simulatedData = [
      {
        id: '1',
        container: 'MD 1-03 | 3.00yd³ | recycle',
        location: '8700 Annapolis Rd Columbia, MD',
        customer: 'Howard HS',
        hauler: 'Howard HS',
        avgFullness: '28%',
        recommendation: '----',
        weeklyChange: '----',
        monthlySavings: '----',
        status: 'NO SCHEDULE',
      },
      {
        id: '2',
        container: 'MD 1-04 | 3.00yd³ | recycle',
        location: '5450 Blue Heron Lane Columbia, MD',
        customer: 'Bryant Woods ES',
        hauler: 'Bryant Woods ES',
        avgFullness: 'No pickups',
        recommendation: '----',
        weeklyChange: '----',
        monthlySavings: '----',
        status: 'NO SCHEDULE',
      },
      {
        id: '3',
        container: 'MD 1-05 | 3.00yd³ | recycle',
        location: '5460 Trumpeter Rd Columbia, MD',
        customer: 'Wilde Lake HS - SGA',
        hauler: 'Wilde Lake HS - SGA',
        avgFullness: '35%',
        recommendation: '----',
        weeklyChange: '----',
        monthlySavings: '----',
        status: 'NO SCHEDULE',
      },
    ];

    setContainers(simulatedData);
  }, []);

  return (
    <Container>
    <Title>Customers</Title>
      <ContainerTable containers={containers} />
    </Container>
  );
};

export default ContainersPage;
