import React from 'react';
import styled from 'styled-components';

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
`;

const Dropdown = ({ options, onChange, disabled = false }) => (
  <Select onChange={onChange} disabled={disabled}>
    {options.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ))}
  </Select>
);

export default Dropdown;
