const awsmobile =/* {
    Auth: {
      region: 'us-east-1', // Tu región
      userPoolId: 'us-east-1_3eTozM6PL', // Tu User Pool ID
      userPoolWebClientId: '2c10hcrrqurnql62bl6mefph0s', // Tu App Client ID
      Cognito: {
        userPoolId: 'us-east-1_3eTozM6PL', // Tu User Pool ID
        userPoolWebClientId: '2c10hcrrqurnql62bl6mefph0s', // Tu App Client ID
      }
    }
  };*/

  {
    Auth: {
      Cognito: {
        userPoolClientId: '2c10hcrrqurnql62bl6mefph0s',
        userPoolId: 'us-east-1_3eTozM6PL',
        loginWith: { // Optional
          oauth: {
            domain: 'https://binviewerapp.auth.us-east-1.amazoncognito.com',
            scopes: ['openid email phone profile aws.cognito.signin.user.admin '],
            redirectSignIn: ['http://localhost:3000/','https://example.com/'],
            redirectSignOut: ['http://localhost:3000/','https://example.com/'],
            responseType: 'code',
          },
          username: 'true',
          email: 'false', // Optional
          phone: 'false', // Optional
        }
      }
    }
  }
  
  export default awsmobile;
  