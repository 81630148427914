import React, { useState } from 'react';
import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const TabButton = styled.button`
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-bottom: 2px solid ${props => (props.active ? '#007bff' : 'transparent')};
  background: none;
  color: ${props => (props.active ? '#007bff' : '#000')};
  cursor: pointer;
  font-size: 16px;

  &:hover {
    color: #007bff;
  }
`;

const TabContent = styled.div`
  padding: 20px;
`;

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <div>
      <TabContainer>
        {tabs.map(tab => (
          <TabButton
            key={tab.key}
            active={activeTab === tab.key}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabContainer>
      <TabContent>
        {tabs.map(tab => activeTab === tab.key && <div key={tab.key}>{tab.content}</div>)}
      </TabContent>
    </div>
  );
};

export default Tabs;
