import React from 'react';
import styled from 'styled-components';
import MaintenanceRow from './MaintenanceRow';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background: #002D62;
  color: white;
  padding: 10px;
  text-align: left;
`;

const MaintenanceTable = ({ issues }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>FIRST OCCURRED</TableHeader>
          <TableHeader>CONTAINER</TableHeader>
          <TableHeader>SERVICE LOCATION</TableHeader>
          <TableHeader>MAINTENANCE REASON</TableHeader>
          <TableHeader>RESPONSIBILITY</TableHeader>
          <TableHeader>STATUS</TableHeader>
        </tr>
      </thead>
      <tbody>
        {issues.map((issue) => (
          <MaintenanceRow key={issue.id} issue={issue} />
        ))}
      </tbody>
    </Table>
  );
};

export default MaintenanceTable;
