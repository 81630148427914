import React from 'react';
import styled from 'styled-components';
import Carousel from '../components/Carousel';
import accessImage from '../utils/accessImage';
import MapComponent from '../components/MapComponent';
import getFileFromFilename from '../utils/getDateFromFilename';
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  height: 550px;
  max-width: 1200px; /* Ajustado para acomodar la imagen grande */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const TextContent = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const AddressLine = styled.p`
  margin: 5px 0;
`;

const CarouselWrapper = styled.div`
  flex: 2;
  margin-left: 20px;
`;

const Modal = ({ isOpen, onClose, container }) => {
  if (!container) return null;
  const images = [...new Set(Object.keys(container?.times).map(time => accessImage(container?.times[time]?.fileName?.Key)))].sort().reverse();
  if (!isOpen) return null;
  const dates = images.map(imgstr => {
    return getFileFromFilename(imgstr)
  });
  const fullness = [];
  const customerName = container.customerName || container.name || 'Customer Name'
  return (
    <ModalBackground>
      <ModalContent>
        <CloseButton onClick={onClose}>Close</CloseButton>
        <ContentWrapper>
          <TextContent>
            <h2>{container.cameraId}</h2>
            <h3>{customerName}</h3>
            <p><strong>Address:</strong> {container.address}</p>
            <AddressLine><strong>City, State, Zip:</strong> {container.city}, {container.state}, {container.zip}</AddressLine>
            <p><strong>Size: </strong> {container.size}</p>
            <p><strong>Average Fullness:</strong> {container.avgFullness}</p>
          </TextContent>
          <CarouselWrapper>
            <Carousel images={images} dates={dates} fullness={fullness} isCircular={false} direction="backward" startFromEnd={false} />
          </CarouselWrapper>
        </ContentWrapper>
      </ModalContent>
    </ModalBackground>
  );
};

export default Modal;
