import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import styled from 'styled-components';
import ContainerFilter from '../../components/Containers/ContainerFilter';
import ContainerList from '../../components/Containers/List';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import CreateContainerModal from '../../components/CreateContainerModal';
import { fetchContainerData, deleteContainer, updateContainer, createContainer } from '../../services/containerService';
import { fetchImageInfo } from '../../services/imageService';
import { updatePercentage } from '../../services/MLService'
import Dropdown from '../../components/Containers/Dropdown';
import MapComponent from '../../components/MapComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { fetchCustomers } from '../../services/customerService';
import { debounce } from 'lodash';

const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
`;

const CreateButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
  
  &:hover {
    background: #0056b3;
  }
`;

const FiltersButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background: #0056b3;
  }
`;

const MapButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: #0056b3;
  }

  & > svg {
    margin-right: 5px;
  }
`;

const SortLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px; /* Adjust padding as needed */
`;

const VisibleRecordsLabel = styled.span`
  margin-left: 20px;
  font-weight: bold;
`;

const ContainerListWrapper = styled.div`
  flex: ${({ isMapVisible }) => (isMapVisible ? '0.5' : '1')};
  transition: flex 0.3s ease;
`;

const MapWrapper = styled.div`
  flex: ${({ isMapVisible }) => (isMapVisible ? '0.5' : '0')};
  transition: flex 0.3s ease;
  overflow: hidden;
`;

const CorrectModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const CorrectModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
  margin-bottom: 20px;
  font-size: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props => props.cancel ? '#6c757d' : '#007bff'};
  color: white;

  &:hover {
    background-color: ${props => props.cancel ? '#5a6268' : '#0056b3'};
  }
`;
const ContainersPage = () => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState(() => () => true);
  const [sortOption, setSortOption] = useState("");
  const [isCorrectModalOpen, setIsCorrectModalOpen] = useState(false);
  const [correctedFullness, setCorrectedFullness] = useState("");
  const initialized = useRef(false);
  const [customers, setCustomers] = useState([]);

  const [filters, setFilters] = useState({
    containerSize: 'All',
    fullness: [0, 100],
  });

  const setImagesToContainer =  async (container) => {
    const imageInfo = await fetchImageInfo(container.cameraId);
    container.times = {};
    //return container;
    imageInfo.forEach(element => {
      const parts = element.Key.split('/').pop().split("_");
      const [place, , time] = parts;
      if (container.containerId === place) {
        container.times[time] = { fileName: element };
      }
    });
    return container;
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const initializeData = async () => {
        try {
          const containerArray = await fetchContainerData();
          const clientInfo = await fetchCustomers();
          setCustomers(clientInfo);
          //const updatedLocations = setLastImagesToContainers(containerArray, s3objects);
          //setImages(s3objects);
          if (initialized.current) {
            setLocations(containerArray);
            setLoading(false);
          }
        } catch (error) {
          if (initialized.current) {
            setError(error.message);
            setLoading(false);
          }
        }
      };

      initializeData();
    }
    return () => {
      initialized.current = false;
    };
  }, []);

  const handleFilterChange = (updatedFilters) => {
    setFilters(updatedFilters);
  };

  const handleCardClick = async (container) => {
    await setImagesToContainer(container);
    setSelectedContainer(container);
    setIsModalOpen(true);
  };

  const handleCorrect = (container) => {
    setSelectedContainer(container);
    setCorrectedFullness(container.fullness || ""); // Inicializa con el valor actual de fullness
    setIsCorrectModalOpen(true);
  };

  const handleCorrectFullnessSubmit = async () => {
    if (selectedContainer) {
      const updatedContainer = {
        ...selectedContainer,
        fullness: Number(correctedFullness)
      };
      await handleUpdateContainer(updatedContainer);
      await updatePercentage(updatedContainer);
      setIsCorrectModalOpen(false);
    }
  };

  const handleCancelCorrect = () => {
    setIsCorrectModalOpen(false);
    setCorrectedFullness("");
  };

  const handleCloseModal = () => {
    const thumbnail = selectedContainer.times[Object.keys(selectedContainer.times).pop()];
    const prop = selectedContainer.times[Object.keys(selectedContainer.times).pop()]
    selectedContainer.times = {};
    selectedContainer.times[prop] = thumbnail;
    setIsModalOpen(false);
    setSelectedContainer(null);
  };

  const handleCloseCreateModal = () => {
    setSelectedContainer(null)
    setIsCreateModalOpen(false);
  };

  const handleToggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleToggleMap = () => {
    setIsMapVisible(!isMapVisible);
  };

  const triggerFind = useMemo(() => debounce((str) => {
    setFilterCriteria(() => (c) => {
      return Object.values(c).some(value =>
        value.toString().toLowerCase().includes(str.toLowerCase())
      );
    });
  }, 300), []);

  const handleDelete = async (id) => {
    try {
      await deleteContainer(id);
      setLocations(locations.filter(container => container.containerId !== id));
    } catch (error) {
      console.error("Error deleting container:", error);
    }
  };

  const handleEdit = (container) => {
    setSelectedContainer(container);
    setIsCreateModalOpen(true);
  };

  const handleUpdateContainer = async (updatedContainer) => {
    if (selectedContainer !== null) {
      try {
        const updatedData = await updateContainer(updatedContainer.containerId, updatedContainer);
        const newLocations = locations.map(container => {
          return container.containerId === updatedContainer.containerId ?
            { containerId: updatedData.containerId, ...updatedData.data, times: container.times } : container
        });
        setLocations(newLocations);
        handleCloseCreateModal();
      } catch (error) {
        console.error("Error updating container:", error);
      }
    } else {
      try {
        const updatedData = await createContainer(updatedContainer.containerId, updatedContainer);
        const newLocations = [...locations];
        newLocations.push({ containerId: updatedData.containerId, ...updatedData.data, times: null });
        setLocations(newLocations);
        handleCloseCreateModal();
      } catch (error) {
        console.error("Error creating container:", error);
      }
    }
    setSelectedContainer(null)
  };

  const sortContainers = useMemo(() => (containers, option) => {
    switch (option) {
      case "updatedHighToLow":
        return containers.sort((a, b) => b.lastUpdate - a.lastUpdate);
      case "updatedLowToHigh":
        return containers.sort((a, b) => a.lastUpdate - b.lastUpdate);
      case "fullnessHighToLow":
        return containers.sort((a, b) => b.fullness - a.fullness);
      case "fullnessLowToHigh":
        return containers.sort((a, b) => a.fullness - b.fullness);
      case "locationNameAToZ":
        return containers.sort((a, b) => a.customerName.localeCompare(b.customerName));
      case "locationNameZToA":
        return containers.sort((a, b) => b.customerName.localeCompare(a.customerName));
      case "containerNameAToZ":
        return containers.sort((a, b) => a.containerId.localeCompare(b.containerId));
      case "containerNameZToA":
        return containers.sort((a, b) => b.containerId.localeCompare(a.containerId));
      case "containerSizeSmallToLarge":
        return containers.sort((a, b) => a.size.localeCompare(b.size));
      case "containerSizeLargeToSmall":
        return containers.sort((a, b) => b.size.localeCompare(a.size));
      default:
        return containers.sort((a, b) => b.fullness - a.fullness);
    }
  }, []);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortedContainers = useMemo(() => sortContainers(
    locations.filter(filterCriteria).filter((c => {
      if (filters.containerSize === "All") return true;
      else return c.size === filters.containerSize;
    })), sortOption).filter(c => {
    return ((c.fullness >= filters.fullness[0]) && (c.fullness <= filters.fullness[1]));
  }), [locations, filterCriteria, filters, sortOption, sortContainers]);

  const dropdownOptions = useMemo(() => [
    { value: "fullnessHighToLow", label: "Fullness High to Low" },
    { value: "fullnessLowToHigh", label: "Fullness Low to High" },
    { value: "locationNameAToZ", label: "Location Name A to Z" },
    { value: "locationNameZToA", label: "Location Name Z to A" },
    { value: "containerNameAToZ", label: "Container Name A to Z" },
    { value: "containerNameZToA", label: "Container Name Z to A" },
    { value: "containerSizeSmallToLarge", label: "Container Size Small to Large" },
    { value: "containerSizeLargeToSmall", label: "Container Size Large to Small" },
    { value: "updatedHighToLow", label: "Last updated New to Old" },
    { value: "updatedLowToHigh", label: "Last updated Old to New" },
  ], []);

  const getCustomerInfo = useCallback((location, customers) => {
    let customerSelected = customers.find(cust => cust.name === location.customerName || location.containerId === cust.customerId)
    if (customerSelected) {
      return customerSelected
    }
    return {}
  }, []);

  const getFullness = async (image, size) => {
    try {
      // Definir la URL del servidor
      const url = `http://localhost:3000/predict?imagePath=${image}&size=${size}`;
      // Realizar la solicitud POST
      const response = await fetch(url, {
        method: 'POST', // Indicar que es un método POST
        headers: {
          'Content-Type': 'application/json', // Indicar que el contenido es JSON
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('Datos recibidos:', data);
      return data.percentageFilled*100;
    } catch (error) {
      console.error('Error al obtener el porcentaje de llenado:', error.message);
      throw error;
    }
  };

  const addresses = useMemo(() => sortedContainers.map(location => {
      const customerInfo = getCustomerInfo(location, customers);
      Object.keys(customerInfo).forEach(key=>{
        location[key] = customerInfo[key]
      })
      //location.fullness = getFullness(location.thumbnail.Key, location.size[0])
      return { id: location.containerId, address: `${location.address}, ${location.city}, ${location.state}`, info: location };
  }).filter(address => address.address !== null), [sortedContainers, customers, getCustomerInfo]);

  if (loading) return <Loader />;

  return (
    <PageContainer>
      <Header>
        <SearchInput placeholder="Search containers..." onChange={(e) => { triggerFind(e.target.value); }} />
        <SortLabel>
          <Dropdown options={dropdownOptions} onChange={handleSortChange} />
          <VisibleRecordsLabel>{sortedContainers.length} records visible</VisibleRecordsLabel>
        </SortLabel>
        <ButtonContainer>
          <FiltersButton onClick={handleToggleFilters}>Filters</FiltersButton>
          <MapButton onClick={handleToggleMap}>
            <FontAwesomeIcon icon={faMap} />
            Map
          </MapButton>
        </ButtonContainer>
      </Header>
      <MainContent>
        <ContainerListWrapper isMapVisible={isMapVisible}>
          <ContainerList containers={sortedContainers} onCardClick={handleCardClick} onDelete={handleDelete} onEdit={handleEdit} onCorrect={handleCorrect} />
        </ContainerListWrapper>
        <MapWrapper isMapVisible={isMapVisible}>
          {isMapVisible && <MapComponent addresses={addresses} visiblePoints={(points)=>{console.log(points)}} onInfoWindowClick={async (e)=>{
                await setImagesToContainer(e);
                setSelectedContainer(e);
                setIsModalOpen(true);
          }}/>}
        </MapWrapper>
      </MainContent>
      {isModalOpen && <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        container={selectedContainer}
      />}
      {isCreateModalOpen && <CreateContainerModal
        isOpen={isCreateModalOpen}
        onClose={handleCloseCreateModal}
        onCreate={handleUpdateContainer}
        container={selectedContainer}
      />}
      {isFilterOpen && <ContainerFilter filters={filters}
        onClose={handleToggleFilters}
        onFilterChange={handleFilterChange} />}
        {isCorrectModalOpen && (
        <CorrectModalOverlay>
          <CorrectModalContent>
            <ModalTitle>{`Correcting Fullness Percentage - ${selectedContainer.id}`}</ModalTitle>
            <Input
              type="number"
              min="0"
              max="100"
              value={correctedFullness}
              onChange={(e) => setCorrectedFullness(e.target.value)}
            />
            <ModalButtonContainer>
              <ModalButton cancel onClick={handleCancelCorrect}>Cancel</ModalButton>
              <ModalButton onClick={handleCorrectFullnessSubmit}>OK</ModalButton>
            </ModalButtonContainer>
          </CorrectModalContent>
        </CorrectModalOverlay>
      )}
    </PageContainer>
  );
};

export default ContainersPage;
