const API_URL = 'https://vdvwbwrl87.execute-api.us-east-1.amazonaws.com/containers/photos/';
const AWS = require('aws-sdk')

export const fetchImageInfo = async (containerId) => {
  try {
    const response = await fetch(`${API_URL}${containerId}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    return result.data
  } catch (error) {
    throw new Error(error.message);
  }
};

export const removeImages = async (containerId) => {
  try {
    //get list of images by container
    const images = await fetchImageInfo(containerId)
    const myBucket = 'us-east-1-992382708231-transferfamily-data'
    const s3 = new AWS.S3({
      accessKeyId: 'AKIA6ODU6NID5R7OF26G',
      signatureVersion: 'v4',
      region: 'us-east-1',
      secretAccessKey: 'MMb4O1Iww31Or6fyDO7yI5HwNIgv4n1bWOjtLkAc'
    });

    images.forEach(element => {
      debugger;
      s3.deleteObject({
        Bucket: myBucket,
        Key: element.Key
      }, function (err, data) { if (err) {console.log(err)}})
    });
    return {}
  } catch (error) {
    throw new Error(error.message);
  }
}
