import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MapComponent from '../components/MapComponent';
import Dropdown from '../components/Containers/Dropdown';
import { fetchCustomers } from '../services/customerService';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 900px;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  align-self: flex-start;

  &:hover {
    background: #0056b3;
  }
`;

const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 14px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
`;

const CreateButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const MapColumn = styled.div`
  overflow: hidden;
  flex: 1;
`;

const CreateContainerModal = ({ isOpen, onClose, onCreate, container, isEditable = false }) => {
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({
    containerId: '',
    geo: {},
    thumbnail: {},
    address: '',
    size: '',
    cameraId: '',
    fullness: 0,
    lastUpdate: '',
    customerName: '',
  });

  useEffect(() => {
    const initializeData = async () => {
      try {
        const clientInfo = await fetchCustomers();
        setCustomers(clientInfo);
      } catch (error) {
        // handle error
      }
    };

    initializeData();
    if (container) {
      setFormData(container);
    } else {
      setFormData({
        containerId: '',
        geo: {},
        thumbnail: {},
        address: '',
        size: '',
        cameraId: '',
        fullness: 0,
        lastUpdate: '',
        customerName: '',
      });
    }
  }, [container]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreate(formData);
  };

  if (!isOpen) return null;
  return (
    <ModalBackground>
      <ModalContent>
        <FormColumn>
          <CloseButton onClick={onClose}>Close</CloseButton>
          <h2 style={{ fontSize: '18px' }}>{container ? 'Edit Container' : 'Create New Container'}</h2>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="containerId">Container ID</Label>
              <Input
                id="containerId"
                name="containerId"
                placeholder="Container ID"
                value={formData.containerId}
                onChange={handleChange}
                disabled={!isEditable}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="cameraId">Camera ID</Label>
              <Input
                id="cameraId"
                name="cameraId"
                placeholder="Camera ID"
                value={formData.cameraId}
                onChange={handleChange}
                disabled={!isEditable}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="customer">Customer</Label>
              <Dropdown
                id="customer"
                name="customer"
                options={customers.map(c => ({ value: c.id, label: c.name }))}
                onChange={(e) => {
                  e.target.name = "customerName";
                  handleChange(e);
                }}
                disabled={!isEditable}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="size">Size</Label>
              <Input
                id="size"
                name="size"
                placeholder="Size"
                value={formData.size}
                onChange={handleChange}
                disabled={!isEditable}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="address">Address</Label>
              <Input
                id="address"
                name="address"
                placeholder="Address"
                value={formData.address}
                onChange={handleChange}
                disabled={!isEditable}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="fullness">Fullness</Label>
              <Input
                id="fullness"
                name="fullness"
                type="number"
                placeholder="Fullness"
                value={formData.fullness}
                onChange={handleChange}
                disabled={!isEditable}
              />
            </FormGroup>
            {isEditable && <CreateButton type="submit">{container ? 'Update' : 'Save'}</CreateButton>}
          </form>
        </FormColumn>
        <MapColumn>
          <MapComponent
            address={formData.address}
            width="100%"
            height="100%"
          />
        </MapColumn>
      </ModalContent>
    </ModalBackground>
  );
};

export default CreateContainerModal;
