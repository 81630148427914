import React from 'react';
import styled from 'styled-components';
import ActivityRow from './ActivityRow';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background: #002D62;
  color: white;
  padding: 10px;
  text-align: left;
`;

const ActivityTable = ({ activities }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>DATE</TableHeader>
          <TableHeader>CUSTOMER</TableHeader>
          <TableHeader>SERVICE LOCATION</TableHeader>
          <TableHeader>CONTAINER</TableHeader>
          <TableHeader>ACTIVITY</TableHeader>
          <TableHeader>FULLNESS</TableHeader>
          <TableHeader>IMAGE</TableHeader>
        </tr>
      </thead>
      <tbody>
        {activities.map((activity) => (
          <ActivityRow key={activity.id} activity={activity} />
        ))}
      </tbody>
    </Table>
  );
};

export default ActivityTable;
