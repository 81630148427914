import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import Reports from '../pages/Insights';
import ContainersPage from '../pages/Containers';
import WasteMetering from '../pages/WasteMetering';
import MaintenancePage from '../pages/Maintenance';
import ActivityPage from '../pages/Activity';
import NotFoundPage from '../pages/404'
const PrivateRoute = ({ element }) => {
  const { isAuthenticated } = {isAuthenticated: true};
  return isAuthenticated ? element : <Navigate to="/" />;
};

function AppRouter() {
  return (
    <Routes>
      <Route path="/insights" element={<PrivateRoute element={<Reports />} />} />
      <Route path="/containers" element={<PrivateRoute element={<ContainersPage />} />} />
      <Route path="/customers" element={<PrivateRoute element={<Home />} />} />
      <Route path="/metering" element={<PrivateRoute element={<WasteMetering />} />} />
      <Route path="/activity" element={<PrivateRoute element={<ActivityPage />} />} />
      <Route path="/maintenance" element={<PrivateRoute element={<MaintenancePage />} />} />
      <Route path="/" element={<Navigate to="/insights" />} />
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRouter;
