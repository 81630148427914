export const updatePercentage = async (container) => {
    const API_URL = 'https://vdvwbwrl87.execute-api.us-east-1.amazonaws.com/containers/';
    try {
        try {
            const response = await fetch(`${API_URL}${container}`);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            let result = await response.json();
            return result.data
          } catch (error) {
            throw new Error(error.message);
          }
    } catch (error) {
      throw new Error(error.message);
    }
  }