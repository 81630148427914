import React from 'react';
import styled from 'styled-components';
import RingChart from '../../components/Insights/RingChart';
import CustomBarChart from '../../components/Insights/BarChart';

const ChartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const ChartWrapper = styled.div`
  flex: 1 1 35%;
  max-width: 35%;
  height: 280px;  // Ajustar la altura de los contenedores de gráficos
  box-sizing: border-box;
  background-color: #f0f0f0;
  padding: 5px; // Reducir el padding a 5px
  border-radius: 8px;
  overflow: hidden;  // Asegurarse de que nada se desborde

  @media (max-width: 768px) {
    flex: 1 1 100%;
    max-width: 100%;
  }

  h3 {
    margin-top: 5px;   // Reducir el espacio superior a la mitad
    margin-bottom: 5px; // Reducir el espacio inferior a la mitad
  }
`;

const colors = ['#0088FE', '#00C49F'];

const InventoryTab = ({ ringChartData, barChartData }) => (
  <ChartContainer>
    <ChartWrapper>
      <h3>CONTAINER UTILIZATION</h3>
      <RingChart data={ringChartData} innerRadius={60} outerRadius={80} colors={colors} />
    </ChartWrapper>
    <ChartWrapper>
      <h3>CONTAINER % FULLNESS</h3>
      <CustomBarChart data={barChartData} xAxisKey="name" barDataKeys={['3 cu yds', '4 cu yds', '6 cubic yds', '8 cubic yds']} colors={colors} />
    </ChartWrapper>
    <ChartWrapper>
      <h3>CONTAINER UTILIZATION BY SIZE</h3>
      <CustomBarChart data={barChartData} xAxisKey="name" barDataKeys={['3 cu yds', '4 cu yds', '6 cubic yds', '8 cubic yds']} colors={colors} />
    </ChartWrapper>
    <ChartWrapper>
      <h3>CONTAINER SIZES</h3>
      <RingChart data={ringChartData} innerRadius={60} outerRadius={80} colors={colors} />
    </ChartWrapper>
  </ChartContainer>
);

export default InventoryTab;
