import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faMinus } from '@fortawesome/free-solid-svg-icons';
import { patchContainer } from '../../services/containerService';
import { removeImages } from "../../services/imageService";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin-right: 10px;
  font-weight: bold;
  min-width: 120px;
`;

const Input = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Select = styled.select`
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
`;

const CreateButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  width: 45%;
`;

const RightColumn = styled.div`
  width: 45%;
`;

const ContainerSection = styled.div`
  margin-top: 10px;
`;

const ContainerList = styled.ul`
  list-style-type: none;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 5px;
`;

const ContainerItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 5px; /* Adjust this value to change the spacing between buttons */
`;

const RemoveButton = styled.button`
  background: red;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: darkred;
  }
`;

const AddContainerSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const AddButton = styled.button`
  background: ${(props) => (props.disabled ? '#ddd' : '#007bff')};
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const ConfirmModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ConfirmModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
`;

const ConfirmButton = styled.button`
  background: ${(props) => (props.cancel ? 'grey' : 'red')};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;

  &:hover {
    background: ${(props) => (props.cancel ? 'darkgrey' : 'darkred')};
  }
`;

const sizeOptions = ['3 cu yds', '4 cu yds', '6 cubic yds', '8 cubic yds'];

const AddCustomerModal = ({ isOpen, onClose, onCreate, client, availableContainers: initialAvailableContainers }) => {
  const [formData, setFormData] = useState({
    customerId: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    size: sizeOptions[0],
  });

  const [containers, setContainers] = useState([]);
  const [availableContainers, setAvailableContainers] = useState(initialAvailableContainers);
  const [selectedContainer, setSelectedContainer] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [containerToRemove, setContainerToRemove] = useState(null);

  useEffect(() => {
    if (client) {
      setFormData({
        customerId: client.customerId || '',
        name: client.name || '',
        address: client.address || '',
        city: client.city || '',
        state: client.state || '',
        zip: client.zip || '',
        contactName: client.contactName || '',
        contactPhone: client.contactPhone || '',
        contactEmail: client.contactEmail || '',
        size: client.size || sizeOptions[0],
      });
      setContainers(client.containers || []);
      setAvailableContainers(initialAvailableContainers.filter(container => !client.containers?.includes(container.containerId)));
    } else {
      setFormData({
        customerId: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        size: sizeOptions[0],
      });
      setContainers([]);
      setAvailableContainers(initialAvailableContainers);
    }
  }, [client, initialAvailableContainers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataTrimmed = {}
    Object.keys(formData).forEach(field=>{
      dataTrimmed[field] = formData[field].trim();
    })
    onCreate({ ...formData, containers });
  };

  const handleAddContainer = () => {
    if (selectedContainer) {
      setContainers([...containers, selectedContainer]);
      setAvailableContainers(availableContainers.filter(container => container.containerId !== selectedContainer));
      setSelectedContainer('');
    }
  };

  const handleRemoveContainer = (container) => {
    setContainers(containers.filter((c) => c !== container));
    setAvailableContainers([...availableContainers, initialAvailableContainers.find(c => c.containerId === container)]);
  };

  const handleOpenConfirmModal = (container) => {
    setContainerToRemove(container);
    setConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
    setContainerToRemove(null);
  };

  const handleDeleteContainer = () => {
    debugger;
    if (containerToRemove) {
      patchContainer(containerToRemove, {deleted: true});
      removeImages(containerToRemove)
    }
  }

  const handleConfirmRemove = () => {
    if (containerToRemove) {
      //delete the container, for real
      handleRemoveContainer(containerToRemove);
      handleDeleteContainer(containerToRemove);
      handleCloseConfirmModal();
    }
  };

  if (!isOpen) return null;

  const availableContainerOptions = availableContainers
    .filter((c) => !containers?.includes(c.containerId))
    .sort((a, b) => a.containerId.localeCompare(b.containerId));

  return (
    <ModalBackground>
      <ModalContent>
        <CloseButton onClick={onClose}>Close</CloseButton>
        <ColumnsContainer>
          <LeftColumn>
            <h2>{client ? 'Edit Customer' : 'Add New Customer'}</h2>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="customerId">Customer ID</Label>
                <Input
                  id="customerId"
                  name="customerId"
                  value={formData.customerId}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="name">Customer Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="address">Street Address</Label>
                <Input
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="state">State</Label>
                <Input
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="zip">ZIP</Label>
                <Input
                  id="zip"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="contactName">Contact Name</Label>
                <Input
                  id="contactName"
                  name="contactName"
                  value={formData.contactName}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="contactPhone">Contact Phone</Label>
                <Input
                  id="contactPhone"
                  name="contactPhone"
                  value={formData.contactPhone}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="contactEmail">Contact Email</Label>
                <Input
                  id="contactEmail"
                  name="contactEmail"
                  value={formData.contactEmail}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="size">Size</Label>
                <Select
                  id="size"
                  name="size"
                  value={formData.size}
                  onChange={handleChange}
                >
                  {sizeOptions.map((size) => (
                    <option key={size} value={size}>{size}</option>
                  ))}
                </Select>
              </FormGroup>
              <CreateButton type="submit">
                {client ? 'Update' : 'Create'}
              </CreateButton>
            </form>
          </LeftColumn>
          <RightColumn>
            <ContainerSection>
              <h3>Containers</h3>
              <AddContainerSection>
                <Select
                  value={selectedContainer}
                  onChange={(e) => setSelectedContainer(e.target.value)}
                >
                  <option value="" disabled>
                    Select a container
                  </option>
                  {availableContainerOptions.map((container) => (
                    <option key={container.containerId} value={container.containerId}>
                      {container.containerId}
                    </option>
                  ))}
                </Select>
                <AddButton
                  type="button"
                  onClick={handleAddContainer}
                  disabled={!selectedContainer}
                >
                  +
                </AddButton>
              </AddContainerSection>
              <ContainerList>
                {containers.map((container) => (
                  <ContainerItem key={container}>
                    {container}
                    <ButtonGroup>
                      <RemoveButton type="button" onClick={() => handleRemoveContainer(container)} title="Remove container from client">
                        <FontAwesomeIcon icon={faMinus} />
                      </RemoveButton>
                      <RemoveButton type="button" onClick={() => handleOpenConfirmModal(container)} title="Remove container from system">
                        <FontAwesomeIcon icon={faTrash} />
                      </RemoveButton>
                    </ButtonGroup>
                  </ContainerItem>
                ))}
              </ContainerList>
            </ContainerSection>
          </RightColumn>
        </ColumnsContainer>
      </ModalContent>
      {confirmModalOpen && (
        <ConfirmModalBackground>
          <ConfirmModalContent>
            <p>Are you sure you want to delete the container forever?</p>
            <ConfirmButton cancel onClick={handleCloseConfirmModal}>No</ConfirmButton>
            <ConfirmButton onClick={handleConfirmRemove}>Yes</ConfirmButton>
          </ConfirmModalContent>
        </ConfirmModalBackground>
      )}
    </ModalBackground>
  );
};

export default AddCustomerModal;
