import * as containerService from "./containerService";
const API_URL = 'https://4k6bqkc272.execute-api.us-east-1.amazonaws.com/clients';

export const fetchCustomers = async () => {
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    result = result.clients.map(cli => ({ id: cli.id, ...cli.data }));
    return result;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createCustomer = async (customer) => {
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: customer.name.trim(), data: { ...customer } }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    customer.containers.forEach(container => {
      containerService.patchContainer(container, { customerName: customer.name });
    });
    return { id: customer.name, ...customer };
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editCustomer = async (customer) => {
  customer.size = customer.size === "3 cubic feet" ? "3 cu yds": customer.size;
  try {
    const response = await fetch(`${API_URL}/${customer.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: customer.id, data: { ...customer } }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    customer.containers.forEach(container => {
      containerService.patchContainer(container, { customerName: customer.name });
    });
    return { id: customer.id, ...customer };
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteCustomer = async (customerId) => {
  try {
    const response = await fetch(`${API_URL}/${customerId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return { id: customerId };
  } catch (error) {
    throw new Error(error.message);
  }
};
