const getFileFromFilename = (imgstr) => {
    const regex = /\/(\d{4})\/(\d{2})\/(\d{2})\//;
    const match = imgstr.match(regex);
    const regex2 = /(\d{8})(\d{6})\.jpg/;
    // Execute the regex on the URL
    const matches = imgstr.match(regex2);
    let hours, min, sec
    if (matches) {
      const timestamp = matches[2]; // Get the time part (HHMMSS)
      hours = timestamp.substring(0, 2); // Extract the hour part (HH)
      min = timestamp.substring(2, 4); // Extract the hour part (HH)
      sec = timestamp.substring(4, 6); // Extract the hour part (HH)
  
    }
    if (match) {
      return `${match[2]}-${match[3]}-${match[1]} - ${hours}:${min}:${sec}`;
    }
    return null;
  }

  export default getFileFromFilename