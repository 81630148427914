import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 460px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  ${(props) => (props.left ? 'left: 10px;' : 'right: 10px;')}
`;

const Badge = styled.span`
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: ${props => props.color || '#007bff'};
  border-radius: 3px;
  margin-right: 5px;
  position: absolute;
  top: 10px;
  left: 10px;

  &:nth-child(2) {
    top: 10px;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  max-width: 98%;
  max-height: 98%;
  width: 70%;
  height: auto;
  object-fit: contain;
`;

const Modal = ({ image, onClose }) => (
  <ModalBackground onClick={onClose}>
    <ModalImage src={image} alt="Enlarged view" />
  </ModalBackground>
);

const Carousel = ({ images, dates, fullness, isCircular, direction = 'forward', startFromEnd = false }) => {
  const initialIndex = startFromEnd ? images.length - 1 : 0;
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      if (direction === 'forward') {
        return prevIndex === 0 ? (isCircular ? images.length - 1 : prevIndex) : prevIndex - 1;
      } else {
        return prevIndex === images.length - 1 ? (isCircular ? 0 : prevIndex) : prevIndex + 1;
      }
    });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      if (direction === 'forward') {
        return prevIndex === images.length - 1 ? (isCircular ? 0 : prevIndex) : prevIndex + 1;
      } else {
        return prevIndex === 0 ? (isCircular ? images.length - 1 : prevIndex) : prevIndex - 1;
      }
    });
  };

  const handleImageClick = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  useEffect(() => {
    setCurrentIndex(startFromEnd ? images.length - 1 : 0);
  }, [startFromEnd, images.length]);

  return (
    <>
      <CarouselContainer>
        {currentIndex !== (direction === 'forward' ? 0 : images.length - 1) && (
          <Arrow left onClick={handlePrev}>
            &lt;
          </Arrow>
        )}
        <Badge color="#ffc107">{dates[currentIndex]}</Badge>
        <Image src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} onClick={() => handleImageClick(images[currentIndex])} />
        {currentIndex !== (direction === 'forward' ? images.length - 1 : 0) && (
          <Arrow onClick={handleNext}>
            &gt;
          </Arrow>
        )}
      </CarouselContainer>
      {isModalOpen && <Modal image={modalImage} onClose={closeModal} />}
    </>
  );
};

export default Carousel;
