import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as IconMenu } from'../assets/icons/6cfb049a-55b0-4dbe-a45b-f577ad60333f.svg'

const SidebarContainer = styled.div`
  width: ${props => (props.isVisible ? '250px' : '0')};
  height: 100vh;
  background: #007bff;
  padding: ${props => (props.isVisible ? '70px 10px 10px 10px' : '0')};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  transition: width 0.3s, padding 0.3s;
`;

const SidebarLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  margin: 3px 0;
  font-size: 15px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  transition: background 0.3s;

  &.active {
    background: #0056b3;
  }

  &:hover {
    background: #0056b3;
  }
`;

const ToggleButton = styled.button`
  position: fixed;
  top: 10px;
  left: ${props => (props.isVisible ? '20px' : '20px')};
  height: 37px;
  background: #007bff;
  color: white;
  border: none;
  padding: 3px;
  cursor: pointer;
  transition: left 0.3s;
  z-index: 1000;

  &:hover {
    background: #0056b3;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

const Sidebar = ({ setIsSidebarVisible, signOut, user }) => {
  const [isVisible, setIsVisible] = useState(true);
  const toggleSidebar = () => {
    setIsVisible(!isVisible);
    setIsSidebarVisible(!isVisible);
  };

  return (
    <>
      <ToggleButton onClick={toggleSidebar} isVisible={isVisible}>
       <IconMenu width="30px" height="30px" />
      </ToggleButton>
      <SidebarContainer isVisible={isVisible}>
        <SidebarLink to="/insights">Insights</SidebarLink>
        <SidebarLink to="/metering">Waste metering</SidebarLink>
        <SidebarLink to="/containers">Containers</SidebarLink>
        <SidebarLink to="/customers">Customers</SidebarLink>
        
        <SidebarLink onClick={()=>{
          signOut()
        }}>Logout</SidebarLink>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
