const AWS = require('aws-sdk')

export default function accessImage(filename) {
    if (!filename) return ''
    const myBucket = 'us-east-1-992382708231-transferfamily-data'
    const myKey = filename
    const signedUrlExpireSeconds = 900 * 1;
    const s3 = new AWS.S3({
        accessKeyId: 'AKIA6ODU6NID5R7OF26G',
        signatureVersion: 'v4',
        region: 'us-east-1',
        secretAccessKey: 'MMb4O1Iww31Or6fyDO7yI5HwNIgv4n1bWOjtLkAc'
    });
    
    const url = s3.getSignedUrl('getObject', {
        Bucket: myBucket,
        Key: myKey,
        Expires: signedUrlExpireSeconds
    })

    //console.log(url)
    return url;
}
