import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ServiceButton from './ServiceButton';

const Card = styled.div`
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0f7fa; /* Azul pastel */
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  @media (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const Name = styled.h3`
  margin: 0 0 10px 0;
`;

const Address = styled.p`
  color: #666;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 1000px) {
    width: 100%;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledServiceButton = styled(ServiceButton)`
  height: 29px;
  @media (max-width: 1000px) {
    flex: 1 1 45%; // Asegurar que los botones se distribuyan en dos columnas
    max-width: 45%; // Asegurar que los botones se distribuyan en dos columnas
  }

  @media (max-width: 768px) {
    width: 100%; // Asegurar que los botones ocupen todo el ancho en pantallas pequeñas
  }
`;

const DeleteButton = styled.button`
  background: red;
  color: white;
  border: none;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
  height: 29px;
  width: 29px;
  &:hover {
    background: darkred;
  }

  @media (max-width: 1000px) {
    flex: 1 1 45%; // Asegurar que el botón se distribuya en dos columnas
    max-width: 45%; // Asegurar que el botón se distribuya en dos columnas
  }

  @media (max-width: 768px) {
    width: 100%; // Asegurar que el botón ocupe todo el ancho en pantallas pequeñas
  }
`;

function CustomerCard({ customer, onDelete }) {
  return (
    <Card>
      <InfoContainer>
        <Name>{customer.name} ({customer.customerId})</Name>
        <Address>{customer.address}</Address>
      </InfoContainer>
      <ButtonContainer>
        <StyledServiceButton count={customer.serviceLocationCount} label="SERVICE LOCATION" />
        <StyledServiceButton count={customer.containers.length} label="CONTAINER" />
        <DeleteButton onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onDelete(customer.id)}}>
          <FontAwesomeIcon icon={faTrash} />
        </DeleteButton>
      </ButtonContainer>
    </Card>
  );
}

export default CustomerCard;
