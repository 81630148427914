import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import accessImage from '../utils/accessImage';
import getFileFromFilename from '../utils/getDateFromFilename';

const MapContainer = styled.div`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100vh'};
`;

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Input = styled.input`
  display: inline-block;
  margin-right: 10px;
`;

const Button = styled.button`
  display: inline-block;
  margin-bottom: 10px;
`;
const InfoWindowContent = styled.div`
  width: 350px;
  height: 350px;
  overflow: hidden;
  scroll: none;
`;

const Card = styled.div`
  background: white;
  overflow: hidden;
  margin: 0px;
  padding: 5px;
  width: 100%; 
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

const Info = styled.div`
  padding: 5px;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 14px;
  color: #333;
`;

const Address = styled.p`
  color: #666;
  margin: 5px 0 5px;
  font-size: 12px;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 3px 5px;
  font-size: 10px;
  color: ${props => props.textColor || 'white'};
  background: ${props => props.color || '#007bff'};
  border-radius: 3px;
  margin-right: 5px;
  position: absolute;
  top: ${props => (props.top ? props.top : '10px')};
  left: ${props => (props.right ? 'auto' : '10px')};
  right: ${props => (props.right ? '10px' : 'auto')};
`;

const getBadgeColors = (fullness) => {
  if (fullness <= 25) {
    return { background: '#28a745', text: '#ffffff' };
  } else if (fullness <= 50) {
    return { background: '#ffc107', text: '#ff0000' };
  } else if (fullness <= 74) {
    return { background: '#fd7e14', text: '#ffff00' };
  } else {
    return { background: '#dc3545', text: '#000000' };
  }
};

const MapComponent = ({ address, width, height, initialCoordinates, enableSearch = false, addresses = [], onInfoWindowClick }) => {
  const [currentAddress, setCurrentAddress] = useState(address);
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState(initialCoordinates ? { lat: initialCoordinates[1], lng: initialCoordinates[0] } : { lat: 0, lng: 0 });
  const [bounds, setBounds] = useState(null);
  const [map, setMap] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const addressCache = useRef({}); // Cache para almacenar resultados de búsqueda de direcciones

  const apiKey = 'AIzaSyD_2_nR03Uj5Z-CsM1H1P2xvuo-wWA8Hmo';

  useEffect(() => {
    const fetchCoordinates = async () => {
      const geocodedMarkers = await Promise.all(
        addresses.map(async (location) => {
          if (addressCache.current[location.address]) {
            // Si la dirección ya está en la cache, devolverla directamente
            return { ...location, ...addressCache.current[location.address] };
          }
          
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              location.address
            )}&key=${apiKey}`
          );
          const data = await response.json();
          if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            const result = { lat, lng };
            
            // Guardar en cache
            addressCache.current[location.address] = result;
            return { ...location, ...result };
          } else {
            console.error(`No results found for address: ${location.address}`);
            return null;
          }
        })
      );

      const validMarkers = geocodedMarkers.filter((marker) => marker !== null);
      setMarkers(validMarkers);

      if (validMarkers.length > 0) {
        const newBounds = new window.google.maps.LatLngBounds();
        validMarkers.forEach(marker => {
          newBounds.extend({ lat: marker.lat, lng: marker.lng });
        });
        setBounds(newBounds);
        if (map) {
          map.fitBounds(newBounds);
        }
      }
    };

    fetchCoordinates();
  }, [addresses, apiKey, map]);

  const handleSearch = useCallback(async () => {
    if (currentAddress) {
      try {
        if (addressCache.current[currentAddress]) {
          // Si la dirección ya está en la cache, usarla
          const { lat, lng } = addressCache.current[currentAddress];
          setCenter({ lat, lng });
          return;
        }

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(currentAddress)}&key=${apiKey}`
        );
        const data = await response.json();
        if (data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          setCenter({ lat, lng });
          addressCache.current[currentAddress] = { lat, lng }; // Guardar en cache
        }
      } catch (error) {
        console.error('Error fetching coordinates for address:', error);
      }
    }
  }, [currentAddress, apiKey]);

  const handleActiveMarker = (marker) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleMouseOut = () => {
    const timeout = setTimeout(() => {
      setActiveMarker(null);
    }, 1000);
    setHoverTimeout(timeout);
  };

  return (
    <div>
      {enableSearch && (
        <SearchContainer>
          <Input
            type="text"
            value={currentAddress}
            onChange={(e) => setCurrentAddress(e.target.value)}
            placeholder="Ingresa una dirección"
          />
          <Button onClick={handleSearch}>Buscar</Button>
        </SearchContainer>
      )}
      <MapContainer width={width} height={height}>
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={center}
            zoom={15}
            onLoad={(mapInstance) => {
              setMap(mapInstance);
              if (bounds) {
                mapInstance.fitBounds(bounds);
              }
            }}
          >
            {markers.map((marker, index) => {
              const { info } = marker;
              const image = accessImage(info.thumbnail.Key);
              const { background, text } = getBadgeColors(info.fullness);
              return (
                <Marker
                  key={index}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  label={marker.id}
                  onMouseOver={() => handleActiveMarker(marker.id)}
                  onMouseOut={handleMouseOut}
                >
                  {activeMarker === marker.id && (
                    <InfoWindow 
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onCloseClick={() => setActiveMarker(null)}
                    >
                      <InfoWindowContent onClick={() => onInfoWindowClick(info)}>
                        <Card>
                          <Image src={image} alt="container image not found" />
                          <Badge color="#ffc107">{`${getFileFromFilename(accessImage(info.thumbnail.Key))}`}</Badge>
                          <Badge color="#6c757d" top="230px">{info.cameraId}</Badge>
                          <Badge color={background} textColor={text} right>{info.fullness}%</Badge>
                          <Info>
                            <Title>{info.customerName || info.name || 'Customer Name'}</Title>
                            <Address>{info.address || 'Address'}, {info.city}, {info.state}, {info.zip}</Address>
                          </Info>
                        </Card>
                      </InfoWindowContent>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}
          </GoogleMap>
        </LoadScript>
      </MapContainer>
    </div>
  );
};

export default MapComponent;
