import React from 'react';
import styled from 'styled-components';
import ContainerCard from './Card';

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
`;

const ContainerList = ({containers, onCardClick, onDelete, onEdit, onCorrect}) => {
  return(
  <List>
    {containers.map(container => {
      return (
      <ContainerCard key={container.containerId} container={container} onCardClick={onCardClick} onDelete={onDelete} onEdit={onEdit} onCorrect={onCorrect}/>
    )})}
  </List>
)};

export default ContainerList;
