import React from 'react';
import SearchFilter from './SearchFilter';
import CustomerList from './CustomerList';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
`;

const Customers = ({ customers, handleOpenModal, onSelectCustomer, onDeleteHandler, onFilterChange }) => {
  return (
    <Container>
      <SearchFilter handleOpenModal={handleOpenModal} onFilterChange={onFilterChange} />
      <CustomerList customers={customers} onSelectCustomer={onSelectCustomer} onDeleteHandler={onDeleteHandler} />
    </Container>
  );
};

export default Customers;
