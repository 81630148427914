import React from 'react';
import styled from 'styled-components';

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const Image = styled.img`
  width: 100px;
  height: 80px;
  object-fit: cover;
`;

const ActivityRow = ({ activity }) => {
  return (
    <tr>
      <TableCell>
        <div>{activity.date}</div>
        <div>{activity.time}</div>
      </TableCell>
      <TableCell>
        <div>{activity.customerName}</div>
        <div>({activity.customerId})</div>
      </TableCell>
      <TableCell>{activity.serviceLocation}</TableCell>
      <TableCell>{activity.container}</TableCell>
      <TableCell>{activity.activity}</TableCell>
      <TableCell>{activity.fullness}</TableCell>
      <TableCell><Image src={activity.image} alt="Container" /></TableCell>
    </tr>
  );
};

export default ActivityRow;
