import React from 'react';
import styled from 'styled-components';
import accessImage from '../../utils/accessImage';
import getFileFromFilename from '../../utils/getDateFromFilename';

const Card = styled.div`
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin: 10px;
  width: calc(100%); // Tres tarjetas por fila con margen
  position: relative;

  @media (max-width: 1024px) {
    width: calc(100% - 10px); // Dos tarjetas por fila en pantallas medianas
  }

  @media (max-width: 768px) {
    width: calc(100%); // Una tarjeta por fila en pantallas pequeñas
  }
`;

const Image = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

const Info = styled.div`
  padding: 15px;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #333;
`;

const Address = styled.p`
  color: #666;
  margin: 5px 0 10px;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  color: ${props => props.textColor || 'white'};
  background: ${props => props.color || '#007bff'};
  border-radius: 3px;
  margin-right: 5px;
  position: absolute;
  top: ${props => (props.top ? props.top : '10px')};
  left: ${props => (props.right ? 'auto' : '10px')};
  right: ${props => (props.right ? '10px' : 'auto')};
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: ${props => props.color || '#007bff'};
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: ${props => props.hoverColor || '#0056b3'};
  }
`;

const CorrectButton = styled(Button)`
  position: absolute;
  top: 215px;
  right: 10px;
  background: #dc3545;
  &:hover {
    background: #c82333;
  }
`;

const getBadgeColors = (fullness) => {
  if (fullness <= 25) {
    return { background: '#28a745', text: '#ffffff' }; // Verde y letras rojas
  } else if (fullness <= 50) {
    return { background: '#ffc107', text: '#ff0000' }; // Amarillo y letras rojas
  } else if (fullness <= 74) {
    return { background: '#fd7e14', text: '#ffff00' }; // Naranja y letras amarillas
  } else {
    return { background: '#dc3545', text: '#000000' }; // Rojo y letras negras
  }
};

const ContainerCard = ({ container, onCardClick, onDelete, onEdit, onCorrect, deleteEnabled = false, editEnabled = false }) => {
  const image = container.thumbnail && container.thumbnail.Key ? accessImage(container.thumbnail.Key) : '';
  const { background, text } = getBadgeColors(container.fullness);
  const customerName = container.customerName || container.name || 'Customer Name';

  return (
    <Card onClick={() => onCardClick(container)}>
      <Image src={image} alt="container image not found" />
      <Badge color="#ffc107">{`${getFileFromFilename(accessImage(container.thumbnail.Key))}`}</Badge>
      <Badge color="#6c757d" top="215px">{container.cameraId}</Badge>
      <Badge color={background} textColor={text} right>{container.fullness}%</Badge>
      {false && <CorrectButton
        onClick={(e) => { e.stopPropagation(); onCorrect(container); }}
        title="correct fullness percentage"
      >
        Correct
      </CorrectButton>}
      <Info>
        <Title>{customerName}</Title>
        <Address>{container.address || 'Address'}, {container.city}, {container.state}, {container.zip}</Address>
      </Info>
      <ButtonContainer>
        {deleteEnabled && <Button color="#dc3545" hoverColor="#c82333" onClick={(e) => { e.stopPropagation(); onDelete(container); }}>Delete</Button>}
        {editEnabled && <Button color="#28a745" hoverColor="#218838" onClick={(e) => { e.stopPropagation(); onEdit(container); }}>Edit</Button>}
      </ButtonContainer>
    </Card>
  );
};

export default ContainerCard;
