import React from 'react';
import styled from 'styled-components';

const FilterContainer = styled.div`
  width: 300px;
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  &:hover {
    color: #0056b3;
  }
`;

const FilterTitle = styled.h2`
  font-size: 20px;
  margin: 0 0 20px 0;
  color: #007bff;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const RangeInput = styled.input`
  width: 100%;
  margin: 10px 0;
`;

const ClearButton = styled.button`
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }
`;

const ContainerFilter = ({ filters, onClose, onFilterChange }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedFilters = {
      ...filters,
      [name]: type === 'checkbox' ? checked : value,
    };
    onFilterChange(updatedFilters);
  };

  const handleRangeChange = (name, index, value) => {
    const newRange = [...filters[name]];
    newRange[index] = value;
    const updatedFilters = {
      ...filters,
      [name]: newRange,
    };
    onFilterChange(updatedFilters);
  };

  const handleClearFilters = () => {
    const clearedFilters = {
      containerSize: 'All',
      contentType: 'All',
      associatedUser: 'All',
      fullness: [0, 100],
      lastServiced: [0, 8],
      scheduledForService: 'Any day of the week',
      troubleshooting: false,
    };
    onFilterChange(clearedFilters);
  };

  return (
    <FilterContainer>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <FilterTitle>Filters</FilterTitle>
      
      <Label>Container Size (YD³)</Label>
      <Select name="containerSize" value={filters.containerSize} onChange={handleChange}>
        <option value="All">All</option>
        <option value='3 cu yds'>3 Cubic Yards</option>
        <option value='4 cu yds'>4 Cubic Yards</option>
        <option value='6 cubic yds'>6 Cubic Yards</option>
        <option value='8 cubic yds'>8 Cubic Yards</option>
        {/* Agrega más opciones según sea necesario */}
      </Select>

      <Label>Fullness</Label>
      <RangeInput
        type="range"
        min="0"
        max="100"
        value={filters.fullness[0]}
        onChange={(e) => handleRangeChange('fullness', 0, e.target.value)}
      />
      <RangeInput
        type="range"
        min="0"
        max="100"
        value={filters.fullness[1]}
        onChange={(e) => handleRangeChange('fullness', 1, e.target.value)}
      />
      <div>{filters.fullness[0]}% - {filters.fullness[1]}%</div>

      {/*<Label>Last Serviced</Label>
      <RangeInput
        type="range"
        min="0"
        max="8"
        value={filters.lastServiced[0]}
        onChange={(e) => handleRangeChange('lastServiced', 0, e.target.value)}
      />
      <RangeInput
        type="range"
        min="0"
        max="8"
        value={filters.lastServiced[1]}
        onChange={(e) => handleRangeChange('lastServiced', 1, e.target.value)}
      />
      <div>{filters.lastServiced[0]} weeks - {filters.lastServiced[1]}+ weeks</div>

      <Label>Scheduled for Service</Label>
      <Select name="scheduledForService" value={filters.scheduledForService} onChange={handleChange}>
        <option value="Any day of the week">Any day of the week</option>
      </Select>

      <Label>
        <input
          type="checkbox"
          name="troubleshooting"
          checked={filters.troubleshooting}
          onChange={handleChange}
        />
        Troubleshooting
      </Label>*/}

      <ClearButton onClick={handleClearFilters}>Clear Filters</ClearButton>
    </FilterContainer>
  );
};

export default ContainerFilter;
