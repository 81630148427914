import React from 'react';
import styled from 'styled-components';

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const StatusButton = styled.button`
  background: ${(props) => (props.status === 'NO SCHEDULE' ? '#ccc' : '#007bff')};
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

const ContainerRow = ({ container }) => {
  return (
    <tr>
      <TableCell>{container.container}</TableCell>
      <TableCell>{container.location}</TableCell>
      <TableCell>{container.customer}</TableCell>
      <TableCell>{container.hauler}</TableCell>
      <TableCell>{container.avgFullness}</TableCell>
      <TableCell>{container.recommendation}</TableCell>
      <TableCell>{container.weeklyChange}</TableCell>
      <TableCell>{container.monthlySavings}</TableCell>
      <TableCell>
        <StatusButton status={container.status}>
          {container.status}
        </StatusButton>
      </TableCell>
    </tr>
  );
};

export default ContainerRow;
