import React from 'react';
import styled from 'styled-components';

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const FilterInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-left: 10px;
`;

const FilterLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const SearchFilter = ({ filter, setFilter }) => {
  return (
    <FilterContainer>
      <FilterLabel>
        <img src="filter-icon.png" alt="Filter" style={{ width: '24px', marginRight: '10px' }} />
        Filter by keyword
      </FilterLabel>
      <FilterInput
        type="text"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search..."
      />
    </FilterContainer>
  );
};

export default SearchFilter;
