import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomBarChart = ({ data, xAxisKey, barDataKeys, colors }) => (
  <ResponsiveContainer width="100%" height="100%">
    <BarChart data={data} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xAxisKey} />
      <YAxis />
      <Tooltip />
      <Legend />
      {barDataKeys.map((key, index) => (
        <Bar key={key} dataKey={key} fill={colors[index % colors.length]} />
      ))}
    </BarChart>
  </ResponsiveContainer>
);

export default CustomBarChart;
