import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Tabs from '../../components/Tabs';
import InventoryTab from './InventoryTab';
import DataDownloadTab from './DataDownloadTab';
import Loader from '../../components/Loader';
import { fetchContainerData } from '../../services/containerService';
import { fetchCustomers } from '../../services/customerService';

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const Reports = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null)
  const [information, setInformation] = useState([]);
  useEffect(() => {
      const initializeData = async () => {
        try {
          const reportInfo = await fetchContainerData();
          const getCustomerInfo =(location, customers) => {
            let customerSelected = customers.find(cust => cust.name === location.customerName || location.containerId === cust.customerId)
            if (customerSelected) {
              return customerSelected
            }
            return {}
          }
          const customers = await fetchCustomers();
          
          const addresses = () => reportInfo.map(location => {
              const customerInfo = getCustomerInfo(location, customers);
              Object.keys(customerInfo).forEach(key=>{
                location[key] = customerInfo[key]
              })
              return { ...location };
          }).filter(address => address.address !== null)
          const processedData = addresses();
          setInformation(processedData);
          setLoading(false);

        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      };

      
      initializeData();
  }, []);

  const ringTempData = {}

  const groupedData = {};
  information.forEach((item)=>{
    //ring information
    if (ringTempData[item.size]) {
      ringTempData[item.size] = ringTempData[item.size] +1;
    } else {
      ringTempData[item.size] = 1;
    }

    //barchart information
    const range = Math.floor(item.fullness / 10) * 10;
    const rangeKey = `${range}-${range + 9}`;

    if (!groupedData[rangeKey]) {
        groupedData[rangeKey] = { '3 cu yds': 0, '4 cu yds': 0, '6 cubic yds': 0, '8 cubic yds': 0, name: rangeKey };
    }

    groupedData[rangeKey][`${item.size}`] += 1;

  })
  const barChartData = Object.values(groupedData)
  const ringChartData = Object.keys(ringTempData).map((key) => ({ name: key, value: ringTempData[key]}));
  const reportsTabs = [
    { key: 'inventory', label: 'Inventory', content: <InventoryTab ringChartData={ringChartData} barChartData={barChartData} /> },
    { key: 'download', label: 'Data Download', content: <DataDownloadTab /> }
  ];
  if (error) return <div>Error: {error.message}</div>;

  if (loading) return <Loader />;

  return (
    <Container>
      <Title>Insights</Title>
      <Tabs tabs={reportsTabs} />
    </Container>
  );
};

export default Reports;
