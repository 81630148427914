import React from 'react';
import styled from 'styled-components';

const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 8px;
  width: 300px;
  margin-right: 10px;
`;

const Select = styled.select`
  padding: 8px;
  margin-right: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.label`
  margin-right: 10px;
`;

const CreateButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }
`;

function SearchFilter({ handleOpenModal, onFilterChange }) {
  return (
    <FilterBar>
      <div>
        <Input type="text" placeholder="Filter by name or ID" onChange={(e) => onFilterChange(e.target.value)} />
        <Select>
          <option value="all">All Users</option>
          {/* Additional options */}
        </Select>
        <CheckboxContainer>
          <CheckboxLabel>
            <input type="checkbox" /> Container Yards
          </CheckboxLabel>
          <CheckboxLabel>
            <input type="checkbox" /> Disposal Sites
          </CheckboxLabel>
        </CheckboxContainer>
      </div>
      <CreateButton onClick={handleOpenModal}>Add Customer</CreateButton>
    </FilterBar>
  );
}

export default SearchFilter;
