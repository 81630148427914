import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 4px 7px;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;

  &:hover {
    background-color: #138496;
  }

  @media (max-width: 1000px) {
    padding: 6px 10px;
    font-size: 9px;
  }

  @media (max-width: 768px) {
    padding: 4px 5px;
    font-size: 10px;
    width: 100%;
    height: 20px;
  }
`;

const Counter = styled.span`
  background-color: white;
  color: #17a2b8;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  @media (max-width: 1000px) {
    font-size: 9px;
    min-width: 20px;
    height: 20px;
  }
`;

function ServiceButton({ count, label, className }) {
  return (
    <Button className={className}>
      <Counter>{count}</Counter>
      {label}
    </Button>
  );
}

export default ServiceButton;
