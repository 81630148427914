import axios from 'axios';

const API_URL = 'https://vdvwbwrl87.execute-api.us-east-1.amazonaws.com/containers';

const fetchContainerData = async () => {
    try {
        const response = await axios.get(API_URL);
        const data = response.data;
        let result= data.containers.map(ca => {
            const res = { containerId: ca.containerId, ...ca.data, times: {} }
            res.customerName = res.customerName || "";
            res.containerId = res.containerId || "";
            res.size = res.size||"";
            res.fullness = +res.fullness||0
            return res;
        })
        result = result.filter(e => {
            return  e.deleted != 'true' && e.deleted != true
        });
        return result
    } catch (error) {
        throw new Error(error.response ? `HTTP error! status: ${error.response.status}` : error.message);
    }
};

const deleteContainer = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? `HTTP error! status: ${error.response.status}` : error.message);
    }
};

const updateContainer = async (id, updatedData) => {
    try {
        const response = await axios.put(`${API_URL}/${id}`, {
            containerId: id,
            data: {
                geo: updatedData.geo,
                thumbnail: updatedData.thumbnail,
                address: updatedData.address,
                size: updatedData.size,
                cameraId: id,
                fullness: updatedData.fullness,
                lastUpdate: updatedData.lastUpdate,
                customerName: updatedData.customerName
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? `HTTP error! status: ${error.response.status}` : error.message);
    }
};

const patchContainer = async (id, updatedData) => {
    debugger;
    try {
        const response = await axios.patch(`${API_URL}/${id}`, {
            containerId: id,
            data: updatedData
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? `HTTP error! status: ${error.response.status}` : error.message);
    }
};

const createContainer = async (id, updatedData) => {
    try {
        const response = await axios.post(API_URL, {
            containerId: id,
            data: {
                geo: updatedData.geo,
                thumbnail: updatedData.thumbnail,
                address: updatedData.address,
                size: updatedData.size,
                cameraId: id,
                fullness: updatedData.fullness,
                lastUpdate: updatedData.lastUpdate,
                customerName: updatedData.customerName
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? `HTTP error! status: ${error.response.status}` : error.message);
    }
};

export { fetchContainerData, deleteContainer, updateContainer, createContainer, patchContainer };
