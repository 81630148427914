import React, { useState, useEffect } from 'react';
import Customers from '../../components/Customers';
import styled from 'styled-components';
import Loader from '../../components/Loader';
import { fetchContainerData } from '../../services/containerService';
import AddCustomerModal from '../../components/Customers/AddCustomerModal';
import { fetchCustomers, createCustomer, editCustomer, deleteCustomer } from '../../services/customerService';

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [information, setInformation] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const fetchCustomerData = async () => {
    try {
      const clientInfo = await fetchCustomers();
      setCustomers(clientInfo);
      setFilteredCustomers(clientInfo);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCreateCustomer = async (newCustomer) => {
    if (!selectedCustomer) {
      try {
        await createCustomer(newCustomer);
        await fetchCustomerData();
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error creating customer:", error);
      }
    } else {
      try {
        await editCustomer({ id: selectedCustomer.id.trim(), ...newCustomer });
        await fetchCustomerData();
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error editing customer:", error);
      }
    }
  };

  const onDeleteHandler = async (id) => {
    try {
      await deleteCustomer(id);
      await fetchCustomerData();
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCustomer(null);
  };

  const handleSelectCustomer = (cust) => {
    setSelectedCustomer(cust);
    setIsModalOpen(true);
  };

  const handleFilterChange = (filterValue) => {
    const filtered = customers.filter(customer => {
      return Object.values(customer).some(val => 
        String(val).toLowerCase().includes(filterValue.toLowerCase())
      );
    });
    setFilteredCustomers(filtered);
  };

  useEffect(() => {
    const initializeData = async () => {
      try {
        const reportInfo = await fetchContainerData();
        await fetchCustomerData();
        setInformation(reportInfo);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    initializeData();
  }, []);

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;
  return (
    <Container>
      <Title>Customers</Title>
      <Customers customers={filteredCustomers} onSelectCustomer={handleSelectCustomer} handleOpenModal={handleOpenModal} onDeleteHandler={onDeleteHandler} onFilterChange={handleFilterChange} />
      {isModalOpen&& <AddCustomerModal
        client={selectedCustomer}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onCreate={handleCreateCustomer}
        availableContainers={information}
      />}
    </Container>
  );
};

export default Home;
