import React from 'react';
import styled from 'styled-components';

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const MaintenanceRow = ({ issue }) => {
  return (
    <tr>
      <TableCell>
        <div>{issue.date}</div>
        <div>{issue.time}</div>
      </TableCell>
      <TableCell>{issue.container}</TableCell>
      <TableCell>{issue.serviceLocation}</TableCell>
      <TableCell>{issue.reason}</TableCell>
      <TableCell>{issue.responsibility}</TableCell>
      <TableCell>{issue.status}</TableCell>
    </tr>
  );
};

export default MaintenanceRow;
