import React from 'react';
import styled from 'styled-components';
import ContainerRow from './ContainerRow';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background: #002D62;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const ContainerTable = ({ containers }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>CONTAINER</TableHeader>
          <TableHeader>LOCATION</TableHeader>
          <TableHeader>CUSTOMER</TableHeader>
          <TableHeader>HAULER</TableHeader>
          <TableHeader>AVG FULLNESS AT PICKUP</TableHeader>
          <TableHeader>RECC.</TableHeader>
          <TableHeader>WEEKLY CHANGE</TableHeader>
          <TableHeader>EST. MONTHLY SAVINGS</TableHeader>
          <TableHeader>STATUS</TableHeader>
        </tr>
      </thead>
      <tbody>
        {containers.map((container) => (
          <ContainerRow key={container.id} container={container} />
        ))}
      </tbody>
    </Table>
  );
};

export default ContainerTable;
