import React, { useState, useEffect } from 'react';
import MaintenanceTable from '../../components/Maintenance/MaintenanceTable';
import SearchFilter from '../../components/Maintenance/SearchFilter';

const MaintenancePage = () => {
  const [issues, setIssues] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    // Simulación de datos
    const simulatedData = [
      {
        id: '1',
        date: 'Sunday',
        time: 'Jun 2, 2024 at 10:58 PM',
        container: 'MD 6-32 | 3.00yd³ | Recycle',
        serviceLocation: 'Bayard Rustin ES - MCPS',
        reason: 'Low battery',
        responsibility: 'CCR Maryland',
        status: 'Open',
      },
    ];

    setIssues(simulatedData);
  }, []);

  const filteredIssues = issues.filter(issue =>
    issue.container.toLowerCase().includes(filter.toLowerCase()) ||
    issue.serviceLocation.toLowerCase().includes(filter.toLowerCase()) ||
    issue.reason.toLowerCase().includes(filter.toLowerCase()) ||
    issue.responsibility.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div>
      <h1>Maintenance</h1>
      <SearchFilter filter={filter} setFilter={setFilter} />
      <div>
        1 total open issues 1 CCR Maryland responsible issues 0 Compology responsible issues 0 Contact support issues
      </div>
      <a href="/">Issues and exception details</a>
      <MaintenanceTable issues={filteredIssues} />
    </div>
  );
};

export default MaintenancePage;
